import axios from "axios";

const modalWrp = document.querySelector(".modal--wrp");
const modal = modalWrp.querySelector(".modal");
const forms = modal.querySelector(".sendForm");
const closeModalBtn = modal.querySelector(".iconClose");
const videoBtn = document.querySelector(".videoBtn");
const modalForm = modalWrp.querySelector(".formModal");
const modalVideo = modalWrp.querySelector(".videoModal");

forms.addEventListener("submit", sendForm);

function sendForm(event) {
  event.preventDefault();
  const { target } = event;
  const token = "934148594:AAFFhjvnaskGHcEuGIZ6qLUB3d8IGTgAMOY";
  const chatId = "-1001352188132";
  const errorChat = "-345939770";
  const messageSuccessful =
    "Вашe сообщение успешно отправлено. Мы вам перезвоним!";
  const messageError = "Упс((( Что-то пошло не так... Попробуйте позже...";

  const message = `<b>Заказ: </b>%0A${target.number.value}`;

  const url = `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&parse_mode=html&text=${message}`;

  axios
    .get(url)
    .then(res => {
      forms.innerHTML = "";
      forms.insertAdjacentHTML(
        "afterbegin",
        `<p class="messageSend">${messageSuccessful}</p>`
      );
    })
    .catch(err => {
      forms.innerHTML = "";
      forms.insertAdjacentHTML(
        "afterbegin",
        `<p class="messageSend">${messageError}</p>`
      );
      axios.get(
        `https://api.telegram.org/bot${token}/sendMessage?chat_id=${errorChat}&text=${err.response.statusText}`
      );
    });
}

function showModal() {
  modalWrp.style.display = "flex";
  modal.classList.add("show");
}

function closeModal() {
  modal.classList.remove("show");
  modal.classList.add("close");

  setTimeout(() => {
    modalWrp.style.display = "none";
    modal.classList.remove("close");
  }, 200);
}

closeModalBtn.addEventListener("click", closeModal);
const btnBuyAll = document.querySelectorAll(".btnBuy");

for (let i = 0; i < btnBuyAll.length; i += 1) {
  btnBuyAll[i].addEventListener("click", showForm);
}

function showForm() {
  showModal();
  console.log(modalVideo.style.display === "block");
  if (modalVideo.style.display === "block") {
    modalVideo.style.display = "none";
  }
  modalForm.style.display = "block";
}

videoBtn.addEventListener("click", showVideo);

function showVideo() {
  showModal();
  if (modalForm.style.display === "block") {
    modalForm.style.display = "none";
  }
  modalVideo.style.display = "block";
}
