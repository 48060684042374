import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";
$(document).ready(function() {
  $(".owl-carousel").owlCarousel({
    items: 1,
    loop: true,
    dots: false,
    nav: true
  });
});
