const canvasToggler = document.querySelector(".offcanvas-toggle");
const offcanvasMenu = document.querySelector(".offcanvas");
const menuWrp = offcanvasMenu.querySelector(".nav--wrp");
const btnClose = offcanvasMenu.querySelector(".close");
const menu = offcanvasMenu.querySelector(".menu");

btnClose.addEventListener("click", closeCanvasMenu);
canvasToggler.addEventListener("click", showCanvasMenu);
menu.addEventListener("click", clickMenu);

function showCanvasMenu() {
  offcanvasMenu.style.display = "block";
  menuWrp.classList.add("showMenu");
}

function closeCanvasMenu() {
  menuWrp.classList.remove("showMenu");
  menuWrp.classList.add("closeMenu");

  setTimeout(() => {
    menuWrp.classList.remove("closeMenu");
    offcanvasMenu.style.display = "none";
  }, 200);
}

function clickMenu({ target }) {
  if (target.className === "menu__item__link") closeCanvasMenu();
}
