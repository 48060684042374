const btnDiscription = document.querySelectorAll(".card__showDiscription");

for (let i = 0; i < btnDiscription.length; i += 1) {
  btnDiscription[i].addEventListener("click", showDiscription);
}

function showDiscription({ currentTarget }) {
  const parent = currentTarget.closest(".card");
  parent.classList.add("visible");
}

const closeDiscriptionBtn = document.querySelectorAll(".card .back .close");
for (let i = 0; i < closeDiscriptionBtn.length; i += 1) {
  closeDiscriptionBtn[i].addEventListener("click", closeDiscription);
}

function closeDiscription({ currentTarget }) {
  const parent = currentTarget.closest(".card.visible");
  parent.classList.remove("visible");
}
